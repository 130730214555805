<template>

    <div class="my-10 flex flex-col bg-jiruto-main justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md ">
            <img class="mx-auto h-17 w-auto" src="../assets/logos/jiruto-logo.png" alt="Jiruto" />
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                Sign in to your account
            </h2>
            <p class="mt-2 text-center text-sm leading-5 text-gray-300 max-w">
                Or
                <router-link to="/signup" tag="a" class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    create an account
                </router-link>
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow mx-2 sm:mx-0 rounded-lg sm:px-10">
                <form @submit.prevent="login" >
                    <div>
                        <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input @change="authFailure = false" v-model="email" id="email" type="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                        </div>
                    </div>

                    <div class="mt-6">
                        <label for="password" class="block text-sm font-medium leading-5 text-gray-700">
                            Password
                        </label>
                        <div class="mt-1 rounded-md shadow-sm relative">
                            <input @change="authFailure = false" v-model="password" id="password" :type="showPassword ? 'text' : 'password'" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />

                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">

                                <svg class="h-6 text-gray-700" fill="none" @click="showPassword = !showPassword"
                                     :class="{'hidden': !showPassword, 'block':showPassword }" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 605 512">
                                    <path fill="currentColor"
                                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                    </path>
                                </svg>

                                <svg class="h-6 text-gray-700" fill="none" @click="showPassword = !showPassword"
                                     :class="{'block': !showPassword, 'hidden':showPassword }" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 640 512">
                                    <path fill="currentColor"
                                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z">
                                    </path>
                                </svg>

                            </div>

                        </div>
                    </div>

                    <p v-if="authFailure" class="text-red-500 text-sm my-2">*Wrong credentials, please check and re-enter</p>

                    <div class="mt-6 flex items-center justify-between">
                        <div class="flex items-center">
                            <input id="remember_me" type="checkbox" class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                            <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-900">
                                Remember me
                            </label>
                        </div>

                        <div class="text-sm leading-5">
                            <button type="button" @click="resetPassword"  class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                Forgot your password?
                            </button>
                        </div>
                    </div>

                    <div class="mt-6">
          <span class="block w-full rounded-md shadow-sm">
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
              Sign in
            </button>
          </span>
                    </div>
                </form>
            </div>
        </div>


        <modal :open="open" v-on:close-modal="open=false">
            <template v-slot:header>
                Password Reset Email Sent!
            </template>
            <template v-slot:text>
                Please check your email. If in the next few minutes no email arrives, please check your spam folder.
            </template>
        </modal>
    </div>
</template>

<script>
    import { required, minLength, email } from 'vuelidate/lib/validators'
    import * as fb from '../firebaseConfig'
    import Modal from  './SuccessModal'
    export default {
        name: "Login",
        components: {
            modal: Modal
        },
        data() {
            return {
                email: "",
                password: "",
                open: false,
                authFailure: false,
                showPassword: false,
            }
        },
        validations: {
            password: {
                required,
                minLength: minLength(8)
            },
            email: {
                required,
                email,
            }
        },
        methods: {
            login() {
                this.$store.dispatch('signin',{email: this.email, password: this.password})
                    .then((resp) => {
                        console.log("Response", resp)
                        this.$router.push('/')
                    })
                    .catch((err) => {
                        console.log("Error logging user in: ", err)
                        // if(err.code.includes("auth")){
                        //     this.authFailure = true
                        // }
                    })
            },
            resetPassword(){
                // this.open = true
                fb.auth.sendPasswordResetEmail(this.email)
                    .then(() => this.open = true)
                    .catch(err => console.log("error reseting the password", err))
            }
        }
    }
</script>

<style scoped>

</style>
